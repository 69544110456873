@use "@angular/material" as mat;

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
    width: 100%;
    height: 100%;
}

body {
    -webkit-overflow-scrolling: touch;
}

* {
    font-family: "Montserrat", sans-serif;
    margin: 0;
    padding: 0;
}

/* theme */
$sewio-palette: (
    50: #e1f3e7,
    100: #b4e1c4,
    200: #82cd9c,
    300: #50b974,
    400: #2baa57,
    500: #059b39,
    600: #049333,
    700: #04892c,
    800: #037f24,
    900: #016d17,
    contrast: (
        50: #252525,
        100: #252525,
        200: #252525,
        300: #252525,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
    ),
);

$sewio-monotone-palette: (
    50: #e1f9e9,
    100: #b5f0c9,
    200: #83e6a5,
    300: #51db81,
    400: #2cd466,
    500: #07cc4b,
    600: #06c744,
    700: #05c03b,
    800: #04b933,
    900: #02ad23,
    contrast: (
        50: #252525,
        100: #252525,
        200: #252525,
        300: #252525,
        400: #252525,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
    ),
);

$sewio-primary: mat.define-palette($sewio-palette, 500);
$sewio-monotone: mat.define-palette($sewio-monotone-palette, 500);

$sewio-typography: mat.define-typography-config(
    $headline-1:
        mat.define-typography-level(
            $font-family: Source Sans Pro,
            $font-size: 96px,
            $font-weight: 300,
        ),
    $headline-2:
        mat.define-typography-level(
            $font-family: Source Sans Pro,
            $font-size: 60px,
            $font-weight: 300,
        ),
    $headline-3:
        mat.define-typography-level(
            $font-family: Source Sans Pro,
            $font-size: 48px,
            $font-weight: 400,
        ),
    $headline-4:
        mat.define-typography-level(
            $font-family: Source Sans Pro,
            $font-size: 34px,
            $font-weight: 400,
        ),
    $headline-5:
        mat.define-typography-level(
            $font-family: Source Sans Pro,
            $font-size: 24px,
            $font-weight: 400,
        ),
    $headline-6:
        mat.define-typography-level(
            $font-family: Source Sans Pro,
            $font-size: 20px,
            $font-weight: 600,
        ),
    $subtitle-1:
        mat.define-typography-level(
            $font-family: Montserrat,
            $font-size: 16px,
            $font-weight: 400,
        ),
    $subtitle-2:
        mat.define-typography-level(
            $font-family: Montserrat,
            $font-size: 14px,
            $font-weight: 500,
        ),
    $body-1:
        mat.define-typography-level(
            $font-family: Montserrat,
            $font-size: 16px,
            $font-weight: 400,
        ),
    $body-2:
        mat.define-typography-level(
            $font-family: Montserrat,
            $font-size: 14px,
            $font-weight: 400,
        ),
    $button:
        mat.define-typography-level(
            $font-family: Montserrat,
            $font-size: 14px,
            $font-weight: 500,
        ),
    $caption:
        mat.define-typography-level(
            $font-family: Montserrat,
            $font-size: 12px,
            $font-weight: 400,
        ),
);

$sewio-theme: mat.define-light-theme(
    (
        color: (
            primary: $sewio-primary,
            accent: $sewio-monotone,
        ),
        typography: $sewio-typography,
        density: -2,
    )
);

@include mat.all-component-themes($sewio-theme);

/* scrollbars */
::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

::-webkit-scrollbar-track {
    background: #ddd;
}

::-webkit-scrollbar-thumb {
    background: #aaa;
}

::-webkit-scrollbar-thumb:hover {
    background: #888;
}

/* autofilled inputs */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px white inset !important;
}

/* tailwind + material intput outline fix */
.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field
    .mdc-notched-outline__notch {
    border-right-style: hidden;
}
